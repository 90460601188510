import { AxiosError } from 'axios';
import {
  CheckBoxesWrapper,
  DialogFormWrapper,
  FormActionsStyle,
  FormBody,
} from 'components/common/Forms/Forms.styled';
import { CardLoader } from 'components/common/Loading';
import { useAuth } from 'context/AuthContext';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { updateCurrentUserEmail } from 'services/firebase';
import { getUserProfile, updateUserProfile } from 'services/userProfile';
import { UserProfileResponse } from 'types/authentication';
import { UserProfileRegisterUpdateFormValues } from 'types/users';
import { errorToast } from 'utils/toast';
import { getUserFormData } from 'utils/user';
import { PatientProfileOrMemberFormData } from '../../../../containers/common/patient/PatientProfileOrMemberFormData';
import { CommonButton, CommonLink } from '../../../common/Forms/Button';
import { toRem } from '../../../../utils/helpers';
import { patientURL } from '../../../../routes/AppRoutes';
import { useCommonTranslation } from '../../../../hooks/i18n/useCommonTranslation';
import CheckboxInput from '../../../common/Forms/CheckboxInput';
import { useConfirmDialog } from '../../../Popup/ConfirmDialog/confirmDialogHook';
import { useGPSurgeriesOptions } from '../../../../hooks/referrals/useGPSurgeriesOptions';
import { usePatientTrusts } from '../../../../hooks/user/usePatientTrusts';
import { getPatientTrustIds } from '../../../../utils/patient';
import { checkErrorCode } from '../../../../utils/errors';
import { ErrorCode } from '../../../../utils/errorCodes';

type Props = {
  setIsFormSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ProfileForm({ setIsFormSuccess }: Props): ReactElement {
  const { t } = useCommonTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { sangixUser, updateAuthUser } = useAuth();
  const existingSangixUser = sangixUser!;
  const { ...rest } = existingSangixUser;

  const { trusts } = usePatientTrusts(sangixUser);
  const { gpSurgeriesOptions } = useGPSurgeriesOptions({
    gpFilters: {
      trustIDs: getPatientTrustIds(existingSangixUser),
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserProfileRegisterUpdateFormValues>({
    mode: 'onChange',
    defaultValues: {
      ...rest,
    },
  });

  const submitForm = (data: UserProfileRegisterUpdateFormValues) => {
    const { userInfo } = getUserFormData(data);
    const userInfoTyped: UserProfileRegisterUpdateFormValues = {
      ...userInfo,
      active_site_id: existingSangixUser.active_site_id,
      email: data.email,
    };
    setIsLoading(true);
    updateUserProfile(userInfoTyped)
      .then(() => {
        getUserProfile()
          .then(({ data }) => {
            if (sangixUser?.email !== userInfoTyped.email) {
              updateCurrentUserEmail(userInfoTyped.email)
                .then(() => {
                  updateAuthUser({
                    sangixUser: data,
                  });
                  setIsFormSuccess(true);
                })
                .catch((err) => {
                  errorToast(err);
                  // Revert to the old email
                  updateUserProfile({
                    ...userInfoTyped,
                    email: (sangixUser as UserProfileResponse).email,
                  });
                });
            } else {
              updateAuthUser({
                sangixUser: data,
              });
              setIsFormSuccess(true);
            }
          })
          .catch((err: AxiosError) => {
            if (
              checkErrorCode(
                err,
                ErrorCode.UPDATE_TO_USER_EMAIL_ALREADY_IN_DATABASE,
              )
            ) {
              errorToast(t('error-codes.UP0006'));
            } else {
              errorToast(err);
            }
          });
      })
      .catch((err: AxiosError) => {
        errorToast(err);
      })
      .finally(() => setIsLoading(false));
  };

  const { confirm } = useConfirmDialog();

  const onSubmit: SubmitHandler<UserProfileRegisterUpdateFormValues> = (
    data,
  ) => {
    if (sangixUser?.email !== data.email) {
      confirm({
        title: t('profile-email-change-description'),
        confirmButtonText: t('yes'),
        confirmButtonVariant: 'danger',
        onConfirm: () => submitForm(data),
      });
    } else {
      submitForm(data);
    }
  };

  return (
    <DialogFormWrapper style={{ paddingTop: 'var(--s2)' }}>
      {trusts && (
        <FormBody onSubmit={handleSubmit(onSubmit)}>
          <PatientProfileOrMemberFormData
            register={register}
            control={control}
            errors={errors}
            trusts={trusts}
            hideEmail={false}
            gpSurgeriesOptions={gpSurgeriesOptions}
          />
          <CheckBoxesWrapper>
            <CheckboxInput
              {...register('sms_optin')}
              label={t('receive-texts')}
              key={'receiveTexts'}
              id={'receiveTexts'}
              isToggle={true}
            />
          </CheckBoxesWrapper>
          <FormActionsStyle>
            <CommonButton
              variant="primary"
              type="submit"
              disabled={Boolean(Object.keys(errors).length)}
              style={{ minWidth: `${toRem(160)}rem` }}
            >
              {t('save-changes')}
            </CommonButton>
            <CommonLink
              type="button"
              variant="secondary"
              style={{ minWidth: `${toRem(160)}rem` }}
              to={patientURL}
            >
              {t('back-to-menu')}
            </CommonLink>
          </FormActionsStyle>
        </FormBody>
      )}
      {isLoading && <CardLoader fillWrapper={true} />}
    </DialogFormWrapper>
  );
}
