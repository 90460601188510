import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { SelectOption } from 'types/common';
import { namespaces } from './locales/namespaces';
import { resources } from './locales/resources';

export const AVAILABLE_LANGUAGES = ['en', 'cs'] as const;
export type Language = (typeof AVAILABLE_LANGUAGES)[number];

export const DEFAULT_LANGUAGE: Language = 'en';

export const languagesOptions: SelectOption<Language>[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Čeština',
    value: 'cs',
  },
];

export const getLanguageLabel = (language: Language) => {
  return languagesOptions.find((option) => option.value === language)?.label;
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'sangix-i18nextLng',
      caches: ['localStorage'],
      cookieMinutes: 365 * 24 * 60, // 1 year
    },
    supportedLngs: AVAILABLE_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    resources: resources,
    ns: namespaces,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackNS: 'common',
  });

export default i18n;
