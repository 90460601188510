/**
 * Check the error codes here
 * https://github.com/Sangix/sangix4-backend/blob/master/docs/ERRORS.md.
 * This enum defines the codes directly used in React app only.
 */
export enum ErrorCode {
  INVALID_CODE_AP0011 = 'AP0011',
  EARLY_CHECKIN_AP0012 = 'AP0012',
  LATE_CHECKIN_AP0013 = 'AP0013',
  USER_UNAUTHORIZED_UM0002 = 'UM0002',
  NO_APPT_FOR_SELF_CHECKIN_FOUND_WC0010 = 'WC0010',
  RESET_PASSWORD_DB_FIREBASE_EMAIL_MISMATCH_WITH_USED_ID = 'UP0027',
  GP_SURGERY_USED_BY_OTHER_DATA_GP0003 = 'GP0003',
  CCG_USED_BY_OTHER_DATA_CG0003 = 'CG0003',
  OTHER_REFERRER_USED_BY_OTHER_DATA_RE0003 = 'RE0003',
  UPDATE_TO_USER_EMAIL_ALREADY_IN_DATABASE = 'UP0006',
}
