import { useLCD } from 'hooks/public/useLCD';
import { useCurrentServerTime } from 'hooks/useTime';
import {
  DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT,
  formatDate,
} from 'utils/dateUtil';
import { DISABLE_SELF_CHECK_IN } from '../../../configs/siteAndTrustAttributes';
import { useSiteBooleanAttributes } from '../../../hooks/useAttribute';
import {
  LCDCheckinNumber,
  LCDCheckinNumberBox,
  LCDFooterStyled,
  LCDFooterTime,
} from './LCD.styled';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';

export const LCDFooter = () => {
  const time = useCurrentServerTime();
  const timeString = formatDate(time, DATE_FNS_DAY_FULL_MONTH_TIME_FORMAT);
  const { code } = useLCD();
  const [disableSelfCheckin] = useSiteBooleanAttributes(DISABLE_SELF_CHECK_IN);
  const { t } = useCommonTranslation();

  return (
    <LCDFooterStyled>
      <LCDFooterTime>{timeString}</LCDFooterTime>
      {!disableSelfCheckin && (
        <LCDCheckinNumberBox>
          {t('lcd-check-in-number')}
          <LCDCheckinNumber>{code}</LCDCheckinNumber>
        </LCDCheckinNumberBox>
      )}
    </LCDFooterStyled>
  );
};
