import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CookieConsentStyled = styled.div`
  display: flex;
  justify-content: space-between;

  gap: var(--s3);
  padding: var(--s3) var(--s4) var(--s3);
  background: var(--disabled);
  flex-wrap: wrap;
`;

export const CookieConsentDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--s2);
`;

export const CookieConsentText = styled.p`
  font-size: var(--h4);
  margin: 0 0 0 0;
`;

export const ConsentLayoutStyled = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const CookieConsentButtonsStyle = styled.div<{ isDesktop: boolean }>`
  margin-top: auto;
  display: flex;
  justify-content: ${({ isDesktop }) => (isDesktop ? 'flex-end' : 'center')};
  gap: var(--s3);
  flex-wrap: wrap;
  button {
    width: ${({ isDesktop }) => (isDesktop ? 'auto' : '100%')};
  }
`;

export const CookiePolicyLink = styled(Link)`
  color: var(--primary-color);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
