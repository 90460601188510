import React, { useContext, useEffect } from 'react';

import {
  useAuthenticationTracking,
  useSendEvent,
} from '../hooks/analytics/useAnalytics';
import { IAnalyticsContext } from '../types/analytics';
import { initGA } from '../utils/ga';
import { clarityConsent, initClarity } from '../utils/clarity';
import { isTestEnvironment } from '../utils/processUtils';
import { useCookiesConsent } from '../hooks/storage/useCookiesConsent';

const defaultContext: IAnalyticsContext = {
  sendEvent: undefined as any,
};

export const AnalyticsContext =
  React.createContext<IAnalyticsContext>(defaultContext);
AnalyticsContext.displayName = 'Analytics Context'; // Only for debugging

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider: React.FC = ({ children }) => {
  const { nonEssentialCookiesApproved } = useCookiesConsent();
  const sendEvent = useSendEvent();

  useAuthenticationTracking(nonEssentialCookiesApproved);
  useEffect(() => {
    if (nonEssentialCookiesApproved) {
      initGA();
    }
    if (!isTestEnvironment) {
      initClarity();
      clarityConsent(!!nonEssentialCookiesApproved);
    }
  }, [nonEssentialCookiesApproved]);

  return (
    <AnalyticsContext.Provider
      value={{
        sendEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
