import React from 'react';
import {
  ConsentLayoutStyled,
  CookieConsentButtonsStyle,
  CookieConsentDescription,
  CookieConsentStyled,
  CookieConsentText,
  CookiePolicyLink,
} from './CookieConsent.styled';
import { useCommonTranslation } from 'hooks/i18n/useCommonTranslation';
import { CommonButton } from '../../../components/common/Forms/Button';
import { Trans } from 'react-i18next';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { COOKIE_POLICY_ROUTE } from '../../../routes/AppRoutes';
import { useHistory } from 'react-router-dom';
import { useCookiesConsent } from '../../../hooks/storage/useCookiesConsent';
import { useThemeMedia } from '../../../hooks/useThemeMedia';
import { mq } from '../../../styles/sizes';

interface Props {
  children: React.ReactNode;
}

export const CookieConsentLayout = ({ children }: Props) => {
  const { t } = useCommonTranslation();
  const { location } = useHistory();

  const { nonEssentialCookiesApproved, updateConsent } = useCookiesConsent();
  const open =
    nonEssentialCookiesApproved === undefined &&
    location.pathname !== COOKIE_POLICY_ROUTE;

  const isDesktop = useThemeMedia(mq.sm);

  return (
    <ConsentLayoutStyled>
      {open && (
        <CookieConsentStyled>
          <CookieConsentDescription>
            <h2>{t('cookies-consent-header')}</h2>
            <CookieConsentText>
              <Trans
                i18nKey="cookies-consent-text"
                components={{
                  cookiePolicyUrl: (
                    <CookiePolicyLink
                      to={COOKIE_POLICY_ROUTE}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('cookies-policy')}
                    </CookiePolicyLink>
                  ),
                  icon: <FaExternalLinkAlt size={12} />,
                }}
              />
            </CookieConsentText>
          </CookieConsentDescription>
          <CookieConsentButtonsStyle isDesktop={isDesktop}>
            <CommonButton
              variant={'secondary'}
              size={'large'}
              onClick={() => {
                updateConsent(false);
              }}
            >
              {t('cookies-consent-reject')}
            </CommonButton>
            <CommonButton
              onClick={() => {
                updateConsent(true);
              }}
              variant={'primary'}
              size={'large'}
              data-testid={'cookies-consent-agree'}
            >
              {t('cookies-consent-agree')}
            </CommonButton>
          </CookieConsentButtonsStyle>
        </CookieConsentStyled>
      )}
      {children}
    </ConsentLayoutStyled>
  );
};
