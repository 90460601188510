import { useLocalStorage } from 'react-use';
import { COOKIES_CONSENT_STORAGE_KEY } from '../../utils/storage';
import { useCallback } from 'react';

export const useCookiesConsent = () => {
  const [localStorageConsent, setLocalStorageConsent] =
    useLocalStorage<boolean>(COOKIES_CONSENT_STORAGE_KEY, undefined);

  const updateConsent = useCallback(
    (consent: boolean) => {
      setLocalStorageConsent(consent);
    },
    [setLocalStorageConsent],
  );
  return {
    nonEssentialCookiesApproved: localStorageConsent,
    updateConsent,
  };
};
