import { useCallback } from 'react';
import { UserFormValues, UserRegisterRequest } from '../../../types/users';
import {
  firebaseDeleteUser,
  signInWithEmail,
} from '../../../services/firebase';
import { useAuth } from '../../../context/AuthContext';
import { register as userRegister } from '../../../services/userProfile';
import { errorToast } from '../../../utils/toast';
import { UserInfo } from 'firebase/auth';
import { useHistory } from 'react-router';
import { RESET_PASSWORD_ROUTE } from '../../../routes/AppRoutes';
import { SIGNUP_SUCCESS } from '../../../configs/constants';
import { UserProfileResponse } from '../../../types/authentication';

export type FirebaseInfo = {
  providerData: (UserInfo | null)[];
  getIdToken: () => Promise<string>;
} | null;

export type RegisterToSangixFirebaseAlreadySignedUp = (
  user: UserRegisterRequest,
) => Promise<unknown>;

export type RegisterToSangixBE = (
  user: UserRegisterRequest,
  firebaseUser?: FirebaseInfo,
) => Promise<UserProfileResponse>;

const useUserRegisterToSangixBE = () => {
  const registerToSangixBe: RegisterToSangixBE = useCallback(
    async (user, firebaseUser) => {
      const firebaseProviderIds = firebaseUser?.providerData
        ?.map((provider: any) => provider?.providerId)
        .sort((a: string, b: string) => a.localeCompare(b))
        .join();

      const token = await firebaseUser?.getIdToken();
      const { data: sangixUser } = await userRegister(
        {
          ...user,
          provider_id: firebaseProviderIds,
        },
        token,
      );
      return sangixUser;
    },
    [],
  );
  return {
    registerToSangixBe,
  };
};

export const useRegisterFirebaseAlreadySignedUp = () => {
  const { firebaseUser, updateAuthUser } = useAuth();
  const { registerToSangixBe } = useUserRegisterToSangixBE();
  const { replace } = useHistory();
  const registerFirebaseAlreadySignedUp: RegisterToSangixFirebaseAlreadySignedUp =
    useCallback(
      async (user) => {
        try {
          const sangixUser = await registerToSangixBe(user, firebaseUser);
          updateAuthUser({
            sangixUser,
          });
          replace(SIGNUP_SUCCESS);
        } catch (error) {
          errorToast(error);
          await firebaseDeleteUser(firebaseUser);
          replace(RESET_PASSWORD_ROUTE);
        }
      },
      [registerToSangixBe, firebaseUser, replace, updateAuthUser],
    );
  return { registerFirebaseAlreadySignedUp };
};

export const useRegisterUserNotInFirebase = () => {
  const { updateAuthUser } = useAuth();
  const { registerToSangixBe } = useUserRegisterToSangixBE();
  // const { replace } = useHistory();
  const registerWithFirebaseSignup = useCallback(
    async (
      emailPwd: { email: string; password: string },
      user: UserFormValues,
    ) => {
      await registerToSangixBe(
        { ...user, password: emailPwd.password },
        undefined,
      );

      updateAuthUser({
        replacePathAfterLogin: SIGNUP_SUCCESS,
      });
      await signInWithEmail(emailPwd.email, emailPwd.password);
    },
    [registerToSangixBe, updateAuthUser],
  );
  return { registerWithFirebaseSignup };
};
