import { CLARITY_PROJECT_ID } from '../configs/constants';
import Clarity from '@microsoft/clarity';

export const initClarity = () => {
  if (CLARITY_PROJECT_ID) {
    Clarity.init(CLARITY_PROJECT_ID);
  }
};

export const clarityConsent = (consent: boolean) => {
  if (CLARITY_PROJECT_ID) {
    Clarity.consent(consent);
  }
};
