import { useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '../../components/common/ProtectedRoute';
import {
  STAFF_ENTERED_CUBICLE_TABS,
  STAFF_ENTERED_CUBICLE_URL,
  StaffEnteredCubicleTab,
} from '../../configs/RoutesConfig';
import { ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY } from '../../configs/constants';
import { useLocalStorage } from 'react-use';
import { StaffEnteredCubicle } from '../../containers/staff/StaffEnteredCubicle';
import { LAST_ENTERED_CUBICLE_TAB_STORAGE_KEY } from '../../utils/storage';

function validTab(tab: string | undefined): StaffEnteredCubicleTab | undefined {
  return STAFF_ENTERED_CUBICLE_TABS.includes(tab as StaffEnteredCubicleTab)
    ? (tab as StaffEnteredCubicleTab)
    : undefined;
}

function getSelectedTab(
  tab: string | undefined,
  lastEnteredCubicleTab?: StaffEnteredCubicleTab,
): StaffEnteredCubicleTab {
  const defaultTab = 'queue';
  return validTab(tab) || validTab(lastEnteredCubicleTab) || defaultTab;
}

export const StaffEnteredCubicleSwitch = () => {
  const location = useLocation();
  const tab = location.pathname.split('/').pop();

  const [lastEnteredCubicleTab, setLastEnteredCubicleTab] =
    useLocalStorage<StaffEnteredCubicleTab>(
      LAST_ENTERED_CUBICLE_TAB_STORAGE_KEY,
      'next',
    );

  const selectedTab = getSelectedTab(tab, lastEnteredCubicleTab);

  useEffect(() => {
    if (selectedTab && selectedTab !== lastEnteredCubicleTab) {
      setLastEnteredCubicleTab(selectedTab);
    }
  }, [lastEnteredCubicleTab, selectedTab, setLastEnteredCubicleTab]);

  return (
    <Switch>
      {STAFF_ENTERED_CUBICLE_TABS.map((tab) => (
        <ProtectedRoute
          exact
          key={tab}
          path={`${STAFF_ENTERED_CUBICLE_URL}/:tab(${tab})`}
          render={() => <StaffEnteredCubicle tab={tab} />}
          hasAnyOfUserRoles={
            ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY
          }
        />
      ))}
      <Redirect to={`${STAFF_ENTERED_CUBICLE_URL}/${selectedTab}`} />
    </Switch>
  );
};
