import { useMemo } from 'react';
import { SiteRoleResponse } from '../../types/authentication';
import { useAuth } from '../../context/AuthContext';

export const filterActiveSiteRoles = (roles?: SiteRoleResponse[]) => {
  return roles?.filter(role => !role.expired);
};

export const useActiveSiteRoles = (roles?: SiteRoleResponse[]) => {
  return useMemo(() => filterActiveSiteRoles(roles), [roles]);
};

export const useSangixUserActiveSiteRoles = () => {
  const { sangixUser } = useAuth();
  return useActiveSiteRoles(sangixUser?.roles);
};