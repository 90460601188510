import { Card, CardFooter, FadeContent, FadeWrapper } from 'components/common';
import CustomScrollbar from 'components/common/CustomScrollbar';
import { CommonLink } from 'components/common/Forms/Button';
import { CardLoader } from 'components/common/Loading';
import { QuillPreview } from 'components/WYSIWYG/WYSIWYG.styled';
import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import React from 'react';
import ReactQuill from 'react-quill';
import { useGDPRTemplate } from '../../../query/storage';
import { COOKIE_POLICY_ROUTE } from '../../../routes/AppRoutes';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';

export const GDPRPage = () => {
  const { sangixUser } = useAuth();
  const { t } = useCommonTranslation();

  const { isLoading, data } = useGDPRTemplate();
  const content = data?.data;

  return (
    <PatientLayout graphics={Boolean(sangixUser) ? 'patient' : 'unauth'}>
      <Card fade>
        <FadeWrapper>
          <CustomScrollbar
            style={{ width: '100%' }}
            autoHeight
            autoHeightMin={'100%'}
            autoHeightMax={'100%'}
          >
            <FadeContent>
              {isLoading && !Boolean(content) ? (
                <CardLoader />
              ) : (
                <QuillPreview>
                  <ReactQuill
                    modules={{
                      toolbar: false,
                    }}
                    value={content as string}
                    readOnly={true}
                    theme="snow"
                  />
                </QuillPreview>
              )}
            </FadeContent>
          </CustomScrollbar>
        </FadeWrapper>
        <CardFooter>
          <CommonLink
            to={COOKIE_POLICY_ROUTE}
            variant="secondary"
            style={{ marginRight: 'var(--s2)' }}
          >
            {t('cookies-policy')}
          </CommonLink>
          <CommonLink to="/" variant="secondary">
            {t('back-to-menu')}
          </CommonLink>
        </CardFooter>
      </Card>
    </PatientLayout>
  );
};
