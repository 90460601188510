import { Loading } from 'components/common/Loading';
import { PATIENT, SUPERADMIN, USER_ROLES_PATHS, UserRoleId } from 'configs/constants';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Backend_Role, IRole } from 'types/common';
import { errorToast } from 'utils/toast';
import { useAuth } from './AuthContext';
import { useRoles } from '../query/roles';
import { UserProfileResponse } from '../types/authentication';
import { useSangixUserActiveSiteRoles } from '../hooks/user/useActiveSiteRoles';

export type MultiRoleCheckFunction = (...role: UserRoleId[]) => boolean;

export interface IRoleContext {
  allRoles?: Backend_Role[];
  currentUserRole?: IRole;
  hasAnyOfRoles: MultiRoleCheckFunction;
  hasAnyOfRolesInAllSites: MultiRoleCheckFunction;
}

const defaultContext: IRoleContext = {
  allRoles: undefined,
  currentUserRole: undefined,
  hasAnyOfRoles: () => false,
  hasAnyOfRolesInAllSites: () => false,
};

export const RoleContext = React.createContext<IRoleContext>(defaultContext);
RoleContext.displayName = 'Role Context'; // Only for debugging

export const useRole = () => useContext(RoleContext);

export const usePatientRole = () => {
  const { allRoles } = useRole();
  return useMemo(
    () => allRoles!.find((role) => role.id === PATIENT)!,
    [allRoles],
  );
};

export const mapUserToActiveRole = (user: UserProfileResponse) => {
  // Superadmin has role item without site_id
  if (user.roles.length === 1 && user.roles[0].role_id === SUPERADMIN) {
    return user.roles[0];
  }
  return user.roles.find((role) => role.site_id === user.active_site_id);
};

export const RoleProvider: React.FC = ({ children }) => {
  const { sangixUser, logout } = useAuth();
  const sangixUserActiveRoles = useSangixUserActiveSiteRoles();
  const [currentUserRole, setCurrentUserRole] = useState<IRole>();
  const { data: roles } = useRoles({
    onError: (r) => {
      errorToast(r);
      logout();
    },
    enabled: !!sangixUser,
  });

  useEffect(() => {
    if (roles && sangixUser) {
      const userRole = roles.data?.find((role) => {
        // We do not allow to have more roles in one site, so this operation is valid
        return role.id === mapUserToActiveRole(sangixUser)?.role_id;
      });
      if (userRole) {
        setCurrentUserRole({
          ...userRole,
          ...USER_ROLES_PATHS[userRole.id],
        });
      } else {
        errorToast('User role not found');
        logout();
      }
    } else {
      setCurrentUserRole(undefined);
    }
  }, [roles, sangixUser, logout]);

  const hasAnyOfRoles = useCallback(
    (...roles: UserRoleId[]) => {
      return Boolean(roles.find((roleId) => currentUserRole?.id === roleId));
    },
    [currentUserRole],
  );

  const hasAnyOfRolesInAllSites = useCallback(
    (...roles: UserRoleId[]) => {
      return Boolean(
        roles.find((roleId) =>
          sangixUserActiveRoles?.some((role) => role.role_id === roleId),
        ),
      );
    },
    [sangixUserActiveRoles],
  );

  if (sangixUser && !roles) {
    return <Loading />;
  }

  return (
    <RoleContext.Provider
      value={{
        allRoles: roles?.data,
        currentUserRole,
        hasAnyOfRoles,
        hasAnyOfRolesInAllSites,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};
