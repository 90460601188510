import { FaBars } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { cssIf } from 'styles/partials/cssIf';
import { toRem, toRemStr } from 'utils/helpers';
import { ReactComponent as SangixLogo } from 'images/Logo/sangix-logo.svg';

export const NavActions = styled.div`
  display: flex;
  height: var(--s4);
`;

export const NavbarWrapper = styled.nav<{
  readonly patientLayout?: boolean;
}>`
  height: var(--s6);
  border-radius: 0 0 var(--s4) var(--s4);
  box-shadow: var(--shadow-3);
  background: var(--white);
  padding: 0 var(--s4);
  display: flex;
  align-items: center;
  gap: var(--s3);
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: var(--s4);
  left: calc(${toRem(160)}rem + var(--s4));
  z-index: 12;
  ${({ patientLayout }) =>
    patientLayout &&
    css`
      left: 50%;
      transform: translateX(-50%);
      right: auto;
      width: 100%;
      max-width: ${toRem(1200)}rem;
    `}

  .left, .right {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
  }

  .right {
    justify-content: flex-end;
  }
`;

export const NavbarLeft = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
  gap: var(--s3);
`;

export const NavbarMiddle = styled.div`
  display: flex;
  font-size: var(--h3);
  font-weight: 600;
`;

export const NavbarRight = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
`;

export const SangixLogoWrapper = styled.div<{ dialog?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: var(--h3);
  font-weight: 600;
  line-height: 1;
  user-select: none;
  ${cssIf('dialog')`
    height: ${toRemStr(64)};
    margin-bottom: var(--s3);
  `}
`;

export const StyledSangixLogo = styled(SangixLogo)`
  color: #fff;
  max-height: ${toRemStr(42)};
  width: auto;
`;

export const Bars = styled(FaBars)<{ open?: boolean }>`
  ${cssIf('open')`
    position: fixed;
    top: ${toRem(16)}rem;
    right: var(--s4);
  `}
  height: var(--s4);
  width: auto;
  z-index: 13;
`;

export const MobileNavMenuBox = styled.nav<{ open?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  z-index: 12;
  padding: var(--s4) var(--s3);
  left: 0;
  transition: transform 0.3s;
  transform: translateX(100%);
  ${cssIf('open')`
    transform: translateX(0);
  `}
`;

export const MobileNavList = styled.ul`
  display: flex;
  font-size: var(--h3);
  flex-direction: column;
`;

export const MobileNavItem = styled.li<{ selected?: boolean }>`
  padding: var(--s2) var(--s3);
  border-radius: var(--s4);
  display: flex;

  ${cssIf('selected')`
    background-color: var(--primary);
    color: var(--white);
  `}
  a {
    color: inherit;
    width: 100%;
    height: 100%;
  }
`;

export const MobileNavBreak = styled.div`
  width: 100%;
  height: 2px;
  background-color: var(--greenaccent);
  background-color: var(--textprimary);
  margin: var(--s2) 0;
`;

export const ExpireIn = styled.div`
  height: var(--s4);
  background-color: var(--rednegative);
  color: var(--white);
  padding: var(--s2) var(--s3);
  border-radius: ${toRemStr(50)};
  display: flex;
  align-items: center;
  font-size: ${toRemStr(14)};

  svg {
    margin-right: var(--s3);
  }
`;
