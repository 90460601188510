import { Card, CardFooter, FadeContent, FadeWrapper } from 'components/common';
import CustomScrollbar from 'components/common/CustomScrollbar';
import { CommonLink } from 'components/common/Forms/Button';
import { PatientLayout } from 'containers/PatientLayout';
import { useAuth } from 'context/AuthContext';
import React from 'react';
import { useCommonTranslation } from '../../../hooks/i18n/useCommonTranslation';
import { CookieTable } from './CookiePolicy.styled';
import CheckboxInput from '../../../components/common/Forms/CheckboxInput';
import { useCookiesConsent } from '../../../hooks/storage/useCookiesConsent';
import { PRIVACY_POLICY_ROUTE } from '../../../routes/AppRoutes';

export const CookiePolicy = () => {
  const { sangixUser } = useAuth();
  const { t } = useCommonTranslation();
  const { nonEssentialCookiesApproved, updateConsent } = useCookiesConsent();

  return (
    <PatientLayout graphics={Boolean(sangixUser) ? 'patient' : 'unauth'}>
      <Card fade>
        <FadeWrapper>
          <CustomScrollbar
            style={{ width: '100%' }}
            autoHeight
            autoHeightMin={'100%'}
            autoHeightMax={'100%'}
          >
            <FadeContent>
              <h2>{t('cookies-policy-title')}</h2>
              <p>{t('cookies-policy-description')}</p>
              <h3>{t('essential-cookies-title')}</h3>
              <p>{t('essential-cookies-description')}</p>
              <h3>{t('non-essential-cookies-title')}</h3>
              <p>{t('non-essential-cookies-description')}</p>
              <h3>{t('cookies-collected-sangix')}</h3>
              <p />
              <CookieTable>
                <thead>
                  <tr>
                    <th>{t('cookie-type')}</th>
                    <th>{t('cookie-name')}</th>
                    <th>{t('cookie-purpose')}</th>
                    <th>{t('cookie-duration')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('essential')}</td>
                    <td>{t('firebase-authentication')}</td>
                    <td>{t('firebase-authentication-purpose')}</td>
                    <td>{t('firebase-authentication-duration')}</td>
                  </tr>
                  <tr>
                    {/* Base on chat GPT translation can be essential because:
                  - The website is only available in multiple languages via cookies (e.g., user-selected language isn't stored in the backend).
                  - The language setting is required for basic functionality (e.g., ensuring users understand critical content).
                  - The user actively selects a language, and the cookie only stores that preference (without tracking behavior).
                  */}
                    <td>{t('essential')}</td>
                    <td>{t('translation')}</td>
                    <td>{t('translation-purpose')}</td>
                    <td>{t('translation-duration')}</td>
                  </tr>
                  <tr>
                    <td>{t('essential')}</td>
                    <td>{t('cookies-consent')}</td>
                    <td>{t('cookies-consent-purpose')}</td>
                    <td>{t('cookies-consent-duration')}</td>
                  </tr>
                  <tr>
                    {/*
                    https://support.google.com/analytics/answer/11397207?hl=en
                    */}
                    <td>{t('non-essential')}</td>
                    <td>{t('google-analytics')}</td>
                    <td>{t('google-analytics-purpose')}</td>
                    <td>{t('google-analytics-duration')}</td>
                  </tr>
                  <tr>
                    <td>{t('non-essential')}</td>
                    <td>{t('clarity')}</td>
                    <td>{t('clarity-purpose')}</td>
                    <td>{t('clarity-duration')}</td>
                  </tr>
                </tbody>
              </CookieTable>
              <p />
              <h3>{t('cookies-collected-sangix-staff-portal-only')}</h3>
              <p />
              <CookieTable>
                <thead>
                  <tr>
                    <th>{t('cookie-type')}</th>
                    <th>{t('cookie-name')}</th>
                    <th>{t('cookie-purpose')}</th>
                    <th>{t('cookie-duration')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('essential')}</td>
                    <td>{t('last-cubicle-entered')}</td>
                    <td>{t('last-cubicle-entered-purpose')}</td>
                    <td>{t('last-cubicle-entered-duration')}</td>
                  </tr>
                  <tr>
                    <td>{t('non-essential')}</td>
                    <td>{t('table-resizing')}</td>
                    <td>{t('table-resizing-purpose')}</td>
                    <td>{t('table-resizing-duration')}</td>
                  </tr>
                </tbody>
              </CookieTable>
              <p />
              <h3>{t('cookies-settings')}</h3>
              <p>{t('cookies-settings-description')}</p>
              <CheckboxInput
                label={t('cookies-settings-allow-non-essential-cookies')}
                name="cookies-consent"
                onCheckedStateChange={(checked) => {
                  updateConsent(checked);
                }}
                checked={nonEssentialCookiesApproved}
              />
            </FadeContent>
          </CustomScrollbar>
        </FadeWrapper>
        <CardFooter>
          <CommonLink
            to={PRIVACY_POLICY_ROUTE}
            variant="secondary"
            style={{ marginRight: 'var(--s2)' }}
          >
            {t('privacy-policy')}
          </CommonLink>
          <CommonLink to="/" variant="secondary">
            Back to Menu
          </CommonLink>
        </CardFooter>
      </Card>
    </PatientLayout>
  );
};
