import styled from 'styled-components';

export const CookieTable = styled.table`
  border: black 1px solid;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid black;
    padding: 8px;
  }

  td:first-child {
    white-space: nowrap;
  }

  td:nth-child(2) {
    white-space: nowrap;
  }
`;
