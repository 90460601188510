import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import ThemeProvider from 'styles/ThemeProvider';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from './context/AuthContext';
import { RoleProvider } from 'context/RoleContext';
import { GlobalAttributesProvider } from './context/GlobalAttributesContext';
import { Toaster } from 'react-hot-toast';
import ErrorBoundary from './containers/ErrorBoundary';
import { AppRoutes } from './routes/AppRoutes';
import { SiteProvider } from './context/SiteContext';
import { PopupProvider } from './context/PopupContext';
import AppRouter from 'containers/AppRouter';
import { PatientProvider } from 'context/patient/PatientContext';
import { MockBEAuthRolePick } from './mirage/utilComponents/MockBEAuthRolePick';
import { makeAndSeedMirageServer } from './mirage/server';
import { queryClient } from './query/QueryClient';
import { AnalyticsProvider } from './context/AnalyticsContext';
import { Suspense } from 'react';
import { Loading } from './components/common/Loading';
import { I18NProvider } from './context/I18NContext';
import {
  isMockBE,
  notProductionEnvironment,
  isTestEnvironment,
  shouldShowMockBERoleSwitch,
} from './utils/processUtils';

const environment = process.env.NODE_ENV;

if (notProductionEnvironment && (isMockBE || isTestEnvironment)) {
  makeAndSeedMirageServer({ environment });
}

const App = () => {
  return (
    // Suspense for lazy loading language files
    <Suspense fallback={<Loading />}>
      <I18NProvider>
        <HelmetProvider>
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <Toaster />
              <PopupProvider>
                <AppRouter>
                  <Helmet title="Sangix Appointments" />
                  <AuthProvider>
                    <SiteProvider>
                      <AnalyticsProvider>
                        <RoleProvider>
                          {shouldShowMockBERoleSwitch && <MockBEAuthRolePick />}
                          <GlobalAttributesProvider>
                            <PatientProvider>
                              <ErrorBoundary>
                                <AppRoutes />
                              </ErrorBoundary>
                            </PatientProvider>
                          </GlobalAttributesProvider>
                        </RoleProvider>
                      </AnalyticsProvider>
                    </SiteProvider>
                  </AuthProvider>
                </AppRouter>
              </PopupProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </HelmetProvider>
      </I18NProvider>
    </Suspense>
  );
};

export default App;
